#about {
  padding: 85px 0 65px 0;
  margin: 0 auto;

}

.about_wrp {
  background-image: url('../../assets/images/bg_right.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: unset !important;
}


#about .section-header h3 {
  font-size: 32px;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
}

@media (max-width: 576px) {
  #about .section-header h3 {
    font-size: 26px;
  }
}



#about .section-header h3::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

#about .section-header h3::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #2f6ab3;
  bottom: 0;
  left: calc(50% - 20px);
}

#about .section-header p {
  text-align: center;
  padding-bottom: 30px;
  color: #333;
}

/* About Us Section
--------------------------------*/
/* #about {
  background: url("../../assets/images/about-bg.jpg") center top no-repeat fixed;
  background-size: cover;
  padding: 60px 0 40px 0;
  position: relative;
} */

/* #about::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.92);
  z-index: 9;
} */

#about .container {
  position: relative;
  z-index: 10;
}

#about .img-fluid {
  width: 100%;
  height: 210px;
  object-fit: cover;
}

#about .about-col {
  background: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  height: 420px;
  margin-bottom: 20px;
}

#about .about-col .img {
  position: relative;
}

#about .about-col .img img {
  border-radius: 4px 4px 0 0;
}

#about .about-col .icon {
  width: 64px;
  height: 64px;
  text-align: center;
  position: absolute;
  background-color: #2f6ab3;
  border-radius: 50%;
  border: 4px solid #fff;
  left: calc(50% - 32px);
  bottom: -30px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

#about .about-col i {
  font-size: 28px;
  line-height: 0;
  color: #fff;
  transition: 0.3s;
}

#about .about-col:hover .icon {
  background-color: #fff;
}

#about .about-col:hover i {
  color: #2f6ab3;
}

#about .about-col h2 {
  color: #000;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  margin: 40px 0 12px 0;
}

#about .about-col h2 a {
  color: #000;
}

#about .about-col h2 a:hover {
  color: #2f6ab3;
}

#about .about-col p {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  margin-bottom: 0;
  padding: 0 20px 20px 20px;
  text-align: center;
}


@media (max-width: 768px) {
  #about {
    padding: 40px 0;
  }
}
