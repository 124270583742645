#letstalk {
  padding: 60px 0;
}

.elementor-title {
  padding: 0 0px 35px 0px;
  text-align: center;
}

.elementor-title h2 {
  font-size: 44px;
  color: #1a1b1e;
  font-weight: 600;
  font-family: "Red Hat Display", sans-serif;
}

.resize-imgs {
  margin-bottom: 24px;
}

.letstalk_item:last-child,
.letstalk_item:last-child .resize-imgs {
  margin-bottom: 0;
}

.center-imgs {
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 210px;
  justify-content: space-between;
  padding: 40px 27px 35px;
  text-align: center;
  width: 100%;
  border: 1px dotted #7eb2ff;
}

.center-imgs:hover {
  box-shadow: 0px 0px 40px 0px rgb(3 143 204/ 28%);
}

.icon-main {
  margin: 0 auto 20px auto;
  padding-top: 16px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.icon-box .i1 {
  background-color: transparent;
  background-image: linear-gradient(210deg, #e62565 29%, #e62565 100%);
}

.icon-box .i2 {
  background-color: transparent;
  background-image: linear-gradient(210deg, #587bf8 29%, #587bf8 100%);
}

.icon-box .i3 {
  background-color: transparent;
  background-image: linear-gradient(210deg, #fd9727 29%, #fd9727 100%);
  box-shadow: 0px 0px 0px 0px rgb(255 187 126 / 44%);
}

.icon-box .i4 {
  background-color: transparent;
  background-image: linear-gradient(210deg, #2c992d 29%, #2c992d 100%);
}

.icon-box .i4:hover .icon-main {
  background-color: transparent;
  background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 0px 10px 0px rgb(255 173 0 / 28%);
}

.icon-box .i4 i:hover .icon-main {
  color: #2c992d;
}

.icon-main i {
  font-size: 37px;
  color: white;
}

.box-title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
}

.content-box h5 a {
  color: #1a1b1e;
}

.content-box p {
  margin-bottom: 0px;
  color: #616161;
  font-size: 18px;
}

.content-box a {
  margin-bottom: 0px;
  color: #070f4d !important;
  font-size: 18px;
}

@media (max-width: 768px) {
  #letstalk {
    padding: 40px 0;
  }

  .elementor-title h2 {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .elementor-title h2 {
    font-size: 26px;
  }
}
