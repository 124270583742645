/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
#gallery {
  padding: 60px 0;
  overflow: hidden;
}

.gallery .swiper-slide {
  transition: 0.3s;
}

.gallery .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.gallery .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #2f6ab3;
}

.gallery .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #2f6ab3;
}

.gallery .swiper-slide-active {
  text-align: center;
}

@media (min-width: 992px) {
  .gallery .swiper-wrapper {
    padding: 40px 0;
  }

  .gallery .swiper-slide-active {
    border: 6px solid #2f6ab3;
    padding: 4px;
    background: #fff;
    z-index: 1;
    transform: scale(1.2);
    margin-top: 10px;
    border-radius: 25px;
  }
}

@media (max-width:768px) {
  #gallery {
    padding: 40px 0;
  }
}


@media screen and (max-width:480px) {
  #gallery .container-fluid {
    padding: 0;
  }
}