.bg-clients,
.testi-content {
  position: relative;
  font-family: "Rubik", sans-serif;
}

.bg-clients {
  background-attachment: fixed;
  background-image: url(../../assets/images/bg-cli.png);
  background-size: cover;
}

.section {
  background-color: #fff;
  padding-bottom: 80px;
  padding-top: 80px;
  position: relative;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.position-relative {
  position: relative !important;
}

.title-border-color:after,
.title-border-color:before {
  border-radius: 50px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
}

.title-border-color:after {
  background-color: #2f6ab3;
  height: 6px;
  top: 13px;
  width: 30px;
}

.bg-clients,
.testi-content {
  position: relative;
}

.testi-content .testi-box {
  background-color: hsla(0, 0%, 100%, 0.25);
  border-radius: 4px;
  -webkit-clip-path: polygon(0 0,
      100% 0,
      100% 88%,
      91% 88%,
      92% 100%,
      87% 88%,
      0 88%);
  clip-path: polygon(0 0, 100% 0, 100% 88%, 91% 88%, 92% 100%, 87% 88%, 0 88%);
  margin: 0 10px;
  padding: 40px 34px 65px;
  min-height: 210px;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
  /* color: rgba(var(--bs-white-rgb),var(--bs-text-opacity))!important; */
}

.fw-light {
  font-weight: 300 !important;
}

.text-white-70 {
  color: hsla(0, 0%, 100%, 0.8);
}

.f-15 {
  font-size: 15px;
}

.fw-light {
  font-weight: 300 !important;
}

/* .testi-content .testi-box .quote-img img {
  position: absolute;
  right: 40px;
  top: 30px;
} */
.testi-content .testi-box .quote-img img {
  position: absolute;
  right: 40px;
  width: 70px;
  top: 0px;
}

.title-border-color:before {
  background-color: #fff;
  height: 2px;
  top: 15px;
  width: 120px;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.float-end {
  float: right !important;
}

.testi-content .client-img img {
  border: 6px solid hsla(0, 0%, 100%, 0.1);
  max-width: 72px;
}

.slick-slide img {
  display: block;
}

.rounded-circle {
  border-radius: 50% !important;
}

.img-fluid,
.img-thumbnail {
  height: auto;
  max-width: 100%;
}

.text-end {
  text-align: right !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.f-17 {
  font-size: 17px;
}

.testi-content .clients-name .after-border {
  border-bottom: 1px solid #2f6ab3;
  height: 1px;
  margin-left: -50px;
  position: absolute;
  top: 10px;
  width: 40px;
}

.text-white-70 {
  color: hsla(0, 0%, 100%, 0.8);
}

/* button.slick-arrow.slick-prev {
  display: none !important;
}

button.slick-arrow.slick-next {
  display: none !important;
} */
.testi-content .testi-box h4 {
  font-size: 28px;
}

.bg-clients .slick-prev:before {
  display: none;
}

.bg-clients .slick-next:before {
  display: none;
}

@media (max-width: 599px) {
  .testi-content .testi-box .quote-img img {
    position: absolute;
    right: 20px;
    width: 55px;
    top: 0px;
  }

  .testi-content .testi-box h4 {
    font-size: 25px;
  }
}


@media (max-width:768px) {
  #clients {
    padding: 40px 0;
  }
}