.pb-70 {
    padding-bottom: 70px;
}

.pt-100 {
    padding-top: 100px;
}
.map-area iframe {
    display: block;
    width: 100%;
    height: 350px;
}
.map-area  {
 margin: 0px 0 40px 0;
}
.contact-card {
    text-align: center;
    padding: 30px 20px;
    border-radius: 10px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 30%);
    box-shadow: 0 0 15px rgb(0 0 0 / 30%);
    margin-bottom: 30px;
    height: 187px;
}

.contact-card i {
    color: #fff;
    background: #2f6ab3;
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 30px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 25px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.contact-card ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.contact-card ul li {
    list-style: none;
    color: #070f4d;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 15px;
}

.contact-card ul li a {
    color: #070f4d;
}

.pb-100 {
    padding-bottom: 100px;
}

.pt-45 {
    padding-top: 45px;
}

.contact-wrap-form {
    background-color: #fff;
    -webkit-box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    padding: 50px;
    border-top: 3px solid #2f6ab3;
    border-bottom: 3px solid #2f6ab3;
}

form {
    display: block;
    margin-top: 0em;
}

.contact-wrap-form .form-group {
    margin-bottom: 25px;
}

.contact-wrap-form .form-group .form-control1 {
    height: 50px;
    color: #070f4d;
    border: 1px solid #e8e8e8;
    background-color: #fcfcff;
    padding: 10px 20px;
    width: 100%;
    background-color: transparent;
    line-height: 1.5;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 2px;
}

.contact-wrap-form .form-group textarea.form-control1 {
    height: auto;
}

.contact-wrap-form .default-btn {
    border: 0;
    outline: none;
}

.form-control1:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.1rem #2f6ab3;
}

.map-area iframe {
    display: block;
    width: 100%;
    height: 400px;
}

.submission-area {
    background-color: rgba(40, 58, 90, 0.9);
    position: relative;
    z-index: 1;
}

.submission-area::before {
    content: "";
    position: absolute;
    z-index: -1;
    background-image: url(https://templates.hibootstrap.com/bonsa/default/assets/img/shape/shape-1.png);
    background-position: center center;
    bottom: 0;
    width: 100%;
    height: 60%;
}

.submission-title h2 {
    font-size: 35px;
    color: #fff;
    text-align: center;
    margin-bottom: 35px;
}

.submission-form {
    max-width: 780px;
    margin: 0 auto;
    height: 70px;
    background-color: #fff;
    border-radius: 50px;
    display: block;
}

.submission-form .form-control1:focus {
    box-shadow: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .submission-form {
        max-width: 100%;
        height: auto;
        border-radius: 5px;
        padding: 25px;
    }

    .submission-form .form-group .form-control1 {
        height: 50px;
        font-size: 14px;
        line-height: 50px;
        padding: 0;
        border-bottom: 1px solid #ddd;
        border-radius: inherit;
    }

    .submission-form .form-group {
        margin-bottom: 20px;
    }

    .submission-form .default-btn {
        float: none;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        padding: 8px 30px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) {
.contact-wrap-form {
       
        padding: 25px;
    
    }
        .submission-form {
        max-width: 100%;
        height: auto;
        border-radius: 5px;
        padding: 25px;
    }

    .submission-form .default-btn {
        float: none;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        font-size: 15px;
        padding: 8px 25px;
    }

    .submission-form .form-group .form-control1 {
        height: 50px;
        font-size: 14px;
        line-height: 50px;
        padding: 0;
        border-bottom: 1px solid #ddd;
        border-radius: inherit;
    }

    .submission-form .default-btn {
        float: none;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        font-size: 15px;
        padding: 8px 25px;
    }
}

.submission-form .form-group {
    margin-bottom: 0;
}

.submission-form .form-group .form-control1 {
    height: 70px;
    font-size: 18px;
    line-height: 70px;
    background-color: transparent;
    border: 0;
    padding: 3px 35px;
}

.submission-form .form-group.border-line {
    position: relative;
}

.submission-form .default-btn {
    border: 0;
    margin-top: 8px;
    float: right;
    margin-right: 10px;
    padding: 12px 50px;
    font-size: 18px;
}

.default-btn {
    background-color: #47b2e4;
    padding: 12px 42px;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin: 0;
    outline: 0;
}

.default-btn:hover::before {
    top: 0;
    background-color: #070f4d;
    color: #fff;
}

.default-btn::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    height: 50%;
    background-color: #47b2e4;
    z-index: -1;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.default-btn:hover::after {
    bottom: 0;
    background-color: #070f4d;
    color: #fff;
}

.default-btn::after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 0%;
    width: 100%;
    height: 50%;
    background-color: #47b2e4;
    z-index: -1;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.contact-detail h2{
font-size: 30px;
margin-bottom: 10px;
}