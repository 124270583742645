.main-wrapper {
    background-color: #284d7b;
    background-image: url(../../assets/images/overlay-right.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.background-overlay1 {
    background-image: url(../../assets/images/overlay.png);
    background-position: bottom center;
    opacity: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.column-gap-default {
    min-height: 400px;
    align-items: center;
    max-width: 1140px;
    display: contents;
}


.top-column {
    position: relative;
    padding: 110px 0;

}

.widget-wrap {
    --e-column-margin-right: 0em;
    --e-column-margin-left: 0em;
    padding: 1em 1em 1em 1em;
    position: relative;
    width: 100%;
}

.widget-container {
    padding: 2em 0em 0em 0em;
}

.widget-container h1 {
    color: white;
    font-family: "Blinker", Sans-serif;
    font-size: 75px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    line-height: 1;
}

.widget-container p {
    margin-top: 0;
    margin-bottom: 0.9rem;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 18px;

}

.widget-container p a {
    color: #40d6ff;
}

.heading-default {
    text-align: center;

}

@media only screen and (max-width: 1024px) {

    .column-gap-default {
        max-width: 1024px;
    }
}

@media only screen and (min-width: 769px) {

    .top-column {
        width: 100%;
    }
}

@media only screen and (max-width: 426px) {

    .widget-container h1 {
        font-size: 50px;
    }
}