/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  height: 70px;
}

#header.header-transparent {
  background: #def0fe;
  box-shadow: 0px 0px 22px -1px #0000002b;
  /* height: 80px; */
}

#header.header-scrolled {
  background: #def0fe;
  height: 70px;
  /* box-shadow: 0px 0px 20px 1px  rgba(127, 137, 161, 0.3); */
}

#header .logo {
  font-size: 25px;
  margin: 0;
  padding: 0;
  font-weight: 500;
  letter-spacing: 1px;
}

#header .logo a {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  #header .logo a {
    font-size: 0;
  }
  #header .logo a img{
    margin-right: 0;
  }
}

#header .logo img {
  max-height: 46px;
  cursor: pointer;
  margin-right: 10px;
}

#header .social-links {
  margin: 0 0 0 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#header .social-links a {
  color: #000000;
  transition: 0.3s;
  line-height: 0;
  margin-left: 15px;
}

#header .social-links a i {
  line-height: 0;
}

#header .social-links a:hover {
  color: #2f6ab3;
}

@media (max-width: 992px) {
  #header .social-links {
    margin: 0 15px 0 0;
  }
}

@media (max-width: 992px) {
  #header .logo {
    font-size: 28px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/

.navbar {
  padding: 0;
  margin: 0 !important;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
  cursor: pointer;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 24px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #111;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #2f6ab3;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 24px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #2f6ab3;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #000000;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed !important;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(45, 43, 70, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #413e66;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #2f6ab3;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul,
.navbar-mobile .dropdown .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li,
.navbar-mobile .dropdown .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a,
.navbar-mobile .dropdown .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i,
.navbar-mobile .dropdown .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a,
.navbar-mobile .dropdown .dropdown ul a:hover,
.navbar-mobile .dropdown .dropdown ul .active:hover,
.navbar-mobile .dropdown .dropdown ul li:hover>a {
  color: #2f6ab3;
}

.navbar-mobile .dropdown>.dropdown-active,
.navbar-mobile .dropdown .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #2f6ab367;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #2f6ab3;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.hideOverflow {
  overflow: hidden;
}