#Benefits {
  padding: 100px 0 30px 0;
}

.sort-title {
  color: #2f6ab3;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: 9px;
}

.Benefits-title h3 {
  font-size: 42px;
  line-height: 58px;
  margin-bottom: 0;
  color: #000000;
  font-weight: 700;
}

.Benefits-detail {
  padding: 70px 100px 0px 16px;
  position: sticky;
  top: 150px;
}

.Benefits-details {
  padding: 0 0 20px;
}

.Benefits-text-editor {
  padding: 0px 0 10px;
}

.Benefits-text-editor p {
  margin-bottom: 15px;
  font-size: 18px;
}

.Benefits-part .elementor-widget-container {
  margin: 0 0 20px 0;
  padding: 30px;
  background-color: #fff;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 14%);
}

.image-box-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: flex;
}

.image-box-img {
  width: 10%;
  margin-right: 15px;
}

.image-box-content {
  width: 100%;
}

.image-box-content h5 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.image-box-description {
  color: #7b7b7b;
  font-size: 16px;
  line-height: 28px;
}

/* <-----> */
#job-offer {
  padding: 50px 0 30px 0;
  background-color: #f2f5fa;
}

.job-offer-title {
  padding: 0px 0px 40px 0px;
  text-align: center;
}

.job-offer-title h3 {
  font-size: 30px;
  color: #000000;
  font-weight: 700;
}

.acc-item {
  box-shadow: 0px 0px 2px 0px #78aeff;
  border-radius: 20px;
}

.acc-toggle {
  color: #2f6ab3;
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
  border-radius: 28px;
}

.acc-toggle {
  color: none;
}

.acc-item ul .l11 {
  list-style: none;
}

.acc-toggle i {
  text-align: center;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 20px;
  background: #444444;
  color: #fff;
  border-radius: 50%;
  font-weight: 700;
}

.acc-item a.collapsed .icon-show {
  display: inline-block;
}

.acc-item a.collapsed .icon-close {
  display: none;
}

.acc-item .icon-show {
  display: none;
}

.acc--content {
  padding: 0 25px 20px;
}

.ot-button {
  margin: 20px 0;
}

.ot-button button {
  padding: 8px 10px 8px 20px;
  color: #fff;
  background-color: #2f6ab3;
  border: none;
  border-radius: 20px;
}

.ot-button button i {
  background: #fff;
  color: #2f6ab3;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  display: inline-block;
  margin-left: 10px;
  border-radius: 50%;
}
@media screen and (max-width:767px) {
  .image-box-img {
      width: 20%;
    }
}