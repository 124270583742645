.elementor-widget-wrap {
  align-content: center;
  align-items: center;
  padding: 0 0 0 50px;
}

@media (min-width: 1405px) {
  .container-fluid {
    width: 1400px;
  }
}

.section1-bg {
  background-color: #f8f8f8;
}

.section1 {
  padding: 70px 0px 20px 0;
}

.sub-title {
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.sub-title h1 {
  font-weight: 700;
  font-size: 2.7rem;
}

.content {
  margin: 0 0 35px 0;
  justify-content: center;
}

.content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.icon-list ul {
  display: flex;
  list-style-type: none;
  padding: 0 0 20px 0;
}

.icon-list ul li {
  margin: 0 8px 0 8px;
  display: flex;
  align-items: center;
}

.icon-list ul li img {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 8px 0 8px;
}

.list-item {
  display: flex;
}

.list-item1 {
  padding-bottom: 20px;
  list-style-type: none;
  padding-left: 10px;
}

.list-item2 {
  padding-bottom: 20px;
  list-style-type: none;
  padding-left: 10px;
}

.list-item1 li {
  padding-left: 28px;
  position: relative;
}

.list-item2 li {
  position: relative;
  padding-left: 28px;
}

.list-item ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #47b2e4;
  line-height: 1;
}

@media (max-width: 1000px) {
  .flex_direction {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 370px) and (max-width: 425px) {
  .sub-title h1 {
    font-size: 30px;
  }

  .elementor-widget-wrap {
    padding: 0 20px;
  }

  /* .icon-list ul li {
    margin: 0px 4px 0 4px;
  
} */

}

@media only screen and (min-width: 200px) and (max-width: 369px) {
  .sub-title h1 {
    font-size: 26px;
    font-weight: 700;
  }

  .elementor-widget-wrap {
    padding: 0 20px;
  }

  .icon-list ul li {
    margin: 0px 4px 0 4px;
  }

 
}

@media screen and (max-width:767px) {
  .elementor-widget-wrap {

    padding: 40px 0 0 50px;
  }
  .row{
    --bs-gutter-x: 0rem;
  }
}