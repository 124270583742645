.modal-header {
    border: none;
}

.modal-footer {
    border: none;
}

.modal-content {
    padding-bottom: 80px;
    overflow: scroll;
}

.bg-primary {
    color: white;
}

.modal-header-career {
    color: black;
    margin-bottom: 10px;
    font-size: 38px;
    font-weight: 700;
    line-height: 52px;
    font-family: poppins, sans-serif;
}

.modal-header-detail p {
    padding: 0 0 40px;
    font-size: 12px;
    color: #54595F;
    font-family: poppins, sans-serif;
    margin-bottom: 15px;

}

.form-control {
    border-radius: 0;
    border: 0 solid #d8d8d8;
    color: #5a5a5a;
    padding: 6px 0;
    border-bottom: 1px solid #d7d7d7;
    resize: none;
    font-size: 16px;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #2f6ab3;
    outline: 0;
    box-shadow: none;
}

.form-group {
    margin-bottom: 27px;
}

label {
    display: none;
}

.modal-body {
    margin: 0 auto;
    width: 50%;
}

.modal-fullscreen .modal-body {
    overflow-y: initial;
}

@media (max-width: 768px) {
    .modal-body {
        width: 85%;
    }
}

@media (max-width: 426px) {
    .modal-body {
        width: 100%;
    }

}

.btn.btn-block {
    display: flex;
    justify-content: center;
    color: white;
    background-color: #2f6ab3;
    margin: 0 auto;
    text-align: center;
    border-radius: 50px;
    box-shadow: 0 7px 16px rgb(12 12 12 / 22%);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    padding: 15px 120px;
}

.btn.btn-block:hover {
    background-color: black;
}

i.bx.bx-right-arrow-circle {
    font-size: 20px;
    margin-top: 1px;
    margin-right: 10px;
}

@media screen and (max-width:767px) {
    .btn.btn-block {
        padding: 15px 90px;
    }
}