.entry.big {
  padding: 60px 0 30px;
  background: url(../../assets/images/bg13.png) top;
  background-color: #4676ac !important;
  background-size: cover;
  z-index: 2;
  position: relative;
  /* height: 500px; */
}

@media (max-width: 999px) {

  .entry.big .container {
    max-width: 720px;
  }
}

.section-title1 {
  margin: 20px 0 40px;
  text-align: center;
  font-size: 44px;
  color: #fff;
}

.entry {
  padding: 50px 0 40px;
  background: url(../../assets/images/blue-bg.jpg) center;
  background-size: cover;
  z-index: 2;
  position: relative;
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

.m-howItWork {
  padding: 0px 0 10px;
}

.m-howItWork {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 25px;
}

.m-howItWork__content {
  position: relative;
  margin: 0 auto;
}

.m-howItWork__list {
  overflow: hidden;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}


[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

.m-howItWork__listItem {
  display: table;
  min-height: 97px;
}

.m-howItWork__listImage--instruct {
  background-image: url(../../assets/images/Process/register.png);
}

.m-howItWork__listImage {
  display: block;
  float: left;
  width: 87px;
  height: 87px;
  margin: 5px 20px 5px 0;
  border-radius: 50px;
  border: 3.88px solid #f6f8fa;
  background-repeat: no-repeat;
  background-position: center center;
}

.m-howItWork__listText {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 50px;
}

.m-howItWorkhdr {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
}

.m-howItWork__listText {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 50px;
}

.m-howItWork__listImage--writers {
  background-image: url(../../assets/images/Process/verification.png);
}

.m-howItWork__listText {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 50px;
}

.m-howItWorkhdr {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
}

.m-howItWork__listImage--rating {
  background-image: url(../../assets/images/Process/underprocess.png);
}

.m-howItWork__listImage {
  display: block;
  float: left;
  width: 87px;
  height: 87px;
  margin: 5px 20px 5px 0;
  border-radius: 50px;
  border: 3.88px solid #f6f8fa;
  background-repeat: no-repeat;
  background-position: center center;
}

.m-howItWork__listText {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 50px;
}

.m-howItWorkhdr {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
}

.m-howItWork__listText {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 50px;
}

.m-howItWork__listImage {
  display: block;
  float: left;
  width: 87px;
  height: 87px;
  margin: 5px 20px 5px 0;
  border-radius: 50px;
  border: 3.88px solid #f6f8fa;
  background-repeat: no-repeat;
  background-position: center center;
}

.m-howItWork__listText {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 50px;
}

.m-howItWorkhdr {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
}

.m-howItWork__listText {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 50px;
}

.m-howItWork__listImage--paper {
  background-image: url(../../assets/images/Process/amountdisposed.png);
}

.m-howItWork__listImage {
  display: block;
  float: left;
  width: 87px;
  height: 87px;
  margin: 5px 20px 5px 0;
  border-radius: 50px;
  border: 3.88px solid #f6f8fa;
  background-repeat: no-repeat;
  background-position: center center;
}

.m-howItWork__listText {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 50px;
}

.m-howItWorkhdr {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
}

.m-howItWork__listText {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 50px;
}

.m-howItWork__listImage--payment {
  background-image: url(../../assets/images/Process/approved.png);
}

@media only screen and (min-width: 375px) {
  .entry.big {
    height: auto;
  }
}

@media only screen and (min-width: 425px) {
  .entry.big {
    height: auto;
  }
}


@media only screen and (min-width: 768px) {
  .entry.big {
    padding: 30px 0 20px;
    height: auto;
  }

  .m-howItWork {
    margin-top: 17px;
  }
}

@media (min-width: 681px) {
  .m-header {
    background-position: right top;
  }

  .m-header__form {
    position: relative;
    top: 25px;
  }

  .m-header__h2 {
    margin-top: -13px;
  }
}

@media (max-width: 680px) {
  .m-howItWork {
    padding: 1px 0;
  }

  .m-howItWork__name {
    position: relative;
    font-size: 30px;
    line-height: 36px;
    padding: 16px 0 13px 0;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }

  .m-howItWork__list {
    padding: 0 0 1px 0;
  }

  .m-howItWork__listItem {
    margin: 25px 0;
  }

  .m-howItWork__listImage {
    margin: 5px 20px 5px 0;
  }
}

@media (min-width: 1000px) {
  .m-howItWork {
    padding: 0px 0 10px;
  }

  .m-howItWork__content {
    max-width: 1200px;
  }

  .m-howItWork__list {
    padding-right: 57.47%;
    position: relative;
    box-sizing: border-box;
  }

  .m-howItWork__list::after,
  .m-howItWork__list::before {
    content: "";
    clear: both;
    display: table;
  }

  .m-howItWork__listItem:nth-child(1) {
    left: 0%;
    margin-right: -55%;
    width: 55%;
    float: left;
    position: relative;
  }

  .m-howItWork__listItem:nth-child(2) {
    left: 40%;
    margin-right: -55%;
    width: 55%;
    float: left;
    position: relative;
    margin-top: 185px;
    margin-left: 30px;
  }

  .m-howItWork__listItem:nth-child(3) {
    left: 80%;
    margin-right: -55%;
    width: 55%;
    float: left;
    position: relative;
    margin-left: -10px;
  }

  .m-howItWork__listItem:nth-child(4) {
    left: 120%;
    margin-right: -55%;
    width: 55%;
    float: left;
    position: relative;
    margin-top: 185px;
    margin-left: 30px;
  }

  .m-howItWork__listItem:nth-child(5) {
    left: 160%;
    margin-right: -55%;
    width: 55%;
    float: left;
    position: relative;
  }

  .m-howItWork__listItem:nth-child(1):after,
  .m-howItWork__listItem:nth-child(3):after {
    content: "";
    position: absolute;
    top: 105px;
    left: 45px;
    width: 174px;
    height: 135px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../assets/images/arrow-01.png);
  }

  .m-howItWork__listItem:nth-child(2):after,
  .m-howItWork__listItem:nth-child(4):after {
    content: "";
    position: absolute;
    top: -105px;
    left: 45px;
    width: 111px;
    height: 98px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../assets/images/arrow-02.png);
  }
}

@media (max-width: 599px) {
  .section-title1 {
    margin: 0 0 30px;
    text-align: center;
    font-size: 26px;
    color: #fff;
  }
}

@media (max-width: 768px) {
  .entry.big {
    padding: 40px 0;
  }

  .section-title1 {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .section-title1 {
    font-size: 26px;
  }

  .m-howItWork__listImage {
    width: 62px;
    height: 62px;
    background-size: 47px;
  }
}