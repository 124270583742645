/* <--------> */
#Technologies {
  background-color: #ecf2ff85;
  border: none;
  padding: 60px 0 !important;
}

.Technologies-wrapper ul.mb-3.nav.nav-tabs {
  display: flex;
  justify-content: center;
  /* border-bottom: 2px solid #80808038 !important; */
}

/* .Technologies-wrapper .nav-tabs .nav-link {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
} */
.Technologies-wrapper .nav-tabs .nav-link {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 3px solid transparent;
}

.Technologies-wrapper .nav-tabs .nav-link.active {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: none !important;
  color: #2f6ab3 !important;
  border-color: #2f6ab3;
  border-radius: revert;
  margin-bottom: 0;
  font-weight: bold;
}

.Technologies-wrapper li.nav-item {
  margin: 0;
}

.justify-content-center {
  justify-content: center !important;
}

.resize-img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 7.5px;
  padding-bottom: 15px;
}

.mt-3 {
  margin-top: 1rem !important;
}

.w-auto {
  width: auto !important;
}

.technology-box {
  height: 186px;
  padding: 16px !important;
  width: 186px;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: space-between;
  padding: 25px 30px;
  text-align: center;
  width: 160px;
}

.technology-box .center-img {
  align-items: center;
  display: flex;
  height: 100%;
}

.technology-box:hover {
  box-shadow: 0 0 20px rgba(3, 143, 204, 0.15);
}

:focus-visible,
a,
a:-webkit-any-link,
a:-webkit-any-link:focus-visible {
  outline: none !important;
  text-decoration: none !important;
}

.nav-tabs .nav-link {
  color: #878787;
  margin-bottom: 0;
}

/* @media (max-width: 1600px)
.resize-img img {
    max-width: 90px!important;
} */
.resize-img img {
  object-fit: contain;
  width: 50px;
  height: 50px;
}

/* .wh-auto {
  height: 60px;
  width: 30px;
} */

img {
  max-width: 100%;
}

img,
svg {
  vertical-align: middle;
}

/* @media (max-width: 1600px)
.technology-box h4 {
    font-size: 20px!important;
    margin-top: 5px;
} */
.technology-box h4 {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  margin-top: 14px;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 576px) {
  .technology-box {
    width: 136px;
    height: 136px;
  }

  .technology-box h4 {
    font-size: 14px;
  }
}

.technology-box:hover h4 {
  color: #0093ed;
}

/* .sp1 {
  margin: 0px 15px 6px 15px;
} */
.content_info {
  overflow: hidden;
}

.nav-tabs {
  padding: 0 15px;
}

@media (max-width:768px) {
  #Technologies {
    padding: 40px 0 !important;
  }
}