/* .section-padding-2 {
  padding: 140px 0 100px;
}
.section1 {
  position: relative;
  z-index: 1;
}
.mb--40 {
  margin-bottom: 40px !important;
}
.heading-left {
  text-align: left;
}
.section-heading .subtitle {
  color: #21ace4;
  font-weight: 500;
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
  margin-bottom: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  h2,
  .h2 {
    font-size: 56px;
  }
}

.title2 a {
  color: rgb(47, 47, 47);
  font-size: 25px;
}
.isotope-project-btn {
  margin-bottom: 55px;
}
.isotope-project-btn button.is-checked {
  color: #0000ff;
}
.isotope-project-btn button {
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 500;
  color: #525260;
  font-family: "DM Sans", sans-serif;
  margin-right: 100px;
  padding: 8px 0;
  position: relative;
}
.isotope-project-btn button.is-checked:after {
  visibility: visible;
  opacity: 1;
}
.isotope-project-btn button:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: red;
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.isotope-project-btn button {
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 500;
  color: #525260;
  font-family: "DM Sans", sans-serif;
  margin-right: 100px;
  padding: 8px 0;
  position: relative;
}
@media only screen and (min-width: 1400px) {
  .row-35 {
    margin-left: -35px;
    margin-right: -35px;
  }
}
.project-grid {
  box-shadow: 0 55px 86px -35px #ecf2f6;
  border-radius: 30px;
  margin-bottom: 40px;
}
.project-grid .thumbnail {
  overflow: hidden;
  border-radius: 30px 30px 0 0;
  position: relative;
}
.project-grid .thumbnail img {
  border-radius: 30px 30px 0 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
  transition: -webkit-transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
  transition: transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
  transition: transform 2s cubic-bezier(0.2, 0.96, 0.34, 1),
    -webkit-transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
}
.project-grid .content {
  background-color: #ffff;
  border-radius: 0 0 30px 30px;
  padding: 40px 50px;
}
.project-grid .content .title {
  letter-spacing: -0.045em;
  margin-bottom: 10px;
}
span {
  display: inline-block;
}
h4 {
  font-size: 32px;
  line-height: 1.2;
}
.title a {
  color: #000000;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.shape-group-7 .shape.shape-1 {
  top: 90px;
  right: 200px;
}
.shape-group-7 .shape {
  position: absolute;
  z-index: -1;
}
.shape-group-7 .shape.shape-2 {
  top: 210px;
  left: 52%;
}
.shape-group-7 .shape.shape-3 {
  top: 40%;
  left: 170px;
}

@media only screen and (max-width: 767px) {
  .project-grid .content {
    padding: 15px;
  }
}
@media only screen and (max-width: 1199px) {
  .project-grid .content {
    padding: 40px;
  }
}

.nav-tabs .nav-link.active {
  color: #355880 !important;
  border-bottom: 3px solid #21ace4 !important;
  box-direction: none;
  background-color: transparent;
  border-top: none;
  border-left: none;

  border-right: none;
}
.nav-item button {
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 500;
  color: #525260;
  font-family: "DM Sans", sans-serif;
  margin-right: 28px;
  padding: 8px 0;
  position: relative;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
  color: #0067bb;
  outline: none;
}

ul.mb-3.nav.nav-tabs.aos-init.aos-animate {
  border-bottom: none !important;
  margin-bottom: 40px !important;
}

.nav-tabs .nav-link {
  border: none;
  color: #878787;
} */

@media (min-width: 1140px) {
  .our-work .container {
    max-width: 1200px;
  }
}

.container {
  max-width: 1440px;
  padding: 0 20px;
}
.section-title3 {
  margin: 100px 0 80px;
  text-align: center;
  font-size: 50px;
}

/* .content-h2,
h2 {
  font-family: 'Inter ExtraBold', sans-serif;
  font-size: 42px;
  line-height: 60px;
  letter-spacing: 0;
  color: #343434;
} */

.our-work .work-sec {
  position: relative;
  margin: 0 auto 100px;
}
.our-work .work-sec-bg {
  max-width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  width: 100%;
  top: -20px;
  right: -20px;
  z-index: -1;
}
.our-work .work-content-sec {
  background-size: 400% 400%;
  transition: all .3s ease-in-out;
  border: 1px solid #ddd;
  align-items: center;
  border-radius: 4px;
}
.p-0 {
  padding: 0 !important;
}
.our-work .work-img img {
  max-width: 100%;
  height: auto;
}
.our-work .work-content {
  padding: 30px;
  border-radius: 20px;
}
.work-content a {
  font-family: 'Inter Medium', sans-serif;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  color: #343434;
}
.our-work .work-content h3 {
  font-weight: 600;
}

.our-work .work-content h3,
.our-work .work-content p {
  color: #343434;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

/* .content-h3,
h3 {
  font-family: 'Inter Medium', sans-serif;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 0;
  color: #343434;
} */
.work-sec h3 span {
  color: #c3c3c3;
  font-weight: 500;
}
.our-work .work-content p {
  color: #343434;
  margin: 0;
}

.our-work .work-content h3,
.our-work .work-content p {
  color: #343434;
}