.agency2 {
  background: url(../../assets/images/bg_new.jpg) center;
  background-size: cover;
  background-repeat: no-repeat;
}

.agency2 .row {
  padding: 140px 0 70px;
}

.animatedBackground {
  background-repeat: repeat !important;
  background-size: auto !important;
  animation: animatedBackground 20s linear infinite;
  -ms-animation: animatedBackground 20s linear infinite;
  -moz-animation: animatedBackground 20s linear infinite;
  -webkit-animation: animatedBackground 20s linear infinite;
}

h3,
h2 {
  font-weight: 600;
  color: #355880;
  margin: 0 0 7px;
}

.red-color {
  color: #21ace4;
}

@keyframes agency2-img {
  from {
    transform: translateZ(0px);
    transition: transform;
  }

  50% {
    transform: translateY(-20px);
    transition: transform;
  }

  to {
    transform: translateZ(0px);
    transition: transform;
  }
}

.agency2 p {
  margin-top: 20px;
  font-size: calc(18px + (21 - 20) * ((100vw - 320px) / (1920 - 300)));
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, helvetica neue, sans-serif !important;
  line-height: 1.67;
  color: #293e40;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-center {
  align-items: center;
  display: flex;
}

/* .holderCircle {
  width: 100%;
  height: 500px;
  border-radius: 100%;
  margin: 0px auto;
  position: relative;
} */
/* .round {
  position: absolute;
  left: 40px;
  top: 45px;
  width: 410px;
  height: 410px;
  border-radius: 100%;
  -webkit-animation: rotation 100s infinite linear;
  background: url(../../assets/images//ccbackground2.png);
  background-size: cover;
} */

@media (min-width: 1400px) {
  .round {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 45px;
    width: 410px;
    height: 410px;
    border-radius: 100%;
    -webkit-animation: rotation 100s infinite linear;
    background: url(../../assets/images//ccbackground2.png);
    background-size: cover;
  }

  .contentCircle {
    width: 250px;
    border-radius: 100%;
    color: #222222;
    position: relative;
    top: 180px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .holderCircle {
    width: 100%;
    height: 500px;
    border-radius: 100%;
    margin: 0px auto;
    position: relative;
  }
}


@media (max-width: 1399px) {
  .holderCircle {
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
    border-radius: 100%;
    margin: 0px auto;
    position: relative;
  }

  .round {
    position: absolute;
    top: 45px;
    width: 320px;
    height: 320px;
    -webkit-animation: rotation 100s infinite linear;
    background: url(../../assets/images//ccbackground2.png);
    background-size: cover;
    border-radius: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }

  .contentCircle {
    border-radius: 100%;
    color: #222222;
    top: 140px;
    width: 320px;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    padding: 0 10px;
  }

  .dotCircle {
    display: none;
  }

}


@media (max-width: 1024px) {
  .holderCircle {
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
    border-radius: 100%;
    margin: 0px auto;
    position: relative;
  }

  .round {
    top: 45px;
    width: 333px;
    height: 333px;
  }

  .contentCircle {
    width: 333px;
  }
}

@media (max-width: 767px) {
  .round {
    position: unset;
  }

  .agency2 .row {
    padding: 110px 0 40px;
  }

  .agency2 h2,
  .agency2 p {
    text-align: center;
  }

  .contentCircle {
    height: 333px;
    top: 0;
    bottom: 0;
  }

  .contentCircle .cirItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .holderCircle {
    width: 100%;
  }

  .round {
    width: 290px;
    height: 290px;
  }

  .contentCircle {
    width: 290px;
    height: 290px;
  }

  .cirItem h2 {
    font-size: 27px !important;
  }

  .cirItem p {
    font-size: 16Px !important;
  }
}



.dotCircle {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  z-index: 20;
}

.dotCircle .itemDot {
  font-size: 40px;
}

.dotCircle .itemDot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  position: absolute;
  background: #ffffff;
  color: #2f6ab3;
  border-radius: 20px;
  text-align: center;
  font-size: 30px;
  z-index: 3;
  cursor: pointer;
  border: 2px solid transparent;
  box-shadow: 0 0 6px rgba(17, 20, 39, 0.15);
}

.agency2 .itemDot i {
  margin: 0 7px;
  padding: 11px;
  font-size: 17px;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 35px rgb(15 0 99 / 10%);
  border-radius: 50%;
  transition: 0.2s linear;
}

.dotCircle .itemDot .forActive {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.contentCircle .cirItem {
  border-radius: 100%;
  color: #222222;
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  opacity: 0;
  transform: scale(0);
  transition: 0.5s;
  font-size: 15px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  margin: auto;
  line-height: 250px;
}

.title-box .title {
  font-weight: 600;
  /* letter-spacing: 2px; */
  position: relative;
  z-index: -1;
  font-size: 34px;
  padding-bottom: 0;
}

.title {
  text-align: center;
  padding-bottom: 40px;
}

.agency2 h2 {
  font-size: calc(27px + (64 - 27) * ((100vw - 320px) / (1920 - 300)));
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, helvetica neue, sans-serif !important;
  line-height: 1.3;
}

.title-box span {
  /* text-shadow: 0 7px 7px rgb(0 0 0 / 15%); */
  font-weight: 600;
  color: #ffffff;
}

.title-box p {
  font-size: 18px;
  line-height: 1.67;
  color: #fff;
  margin: 0;
}

.agency2 p {
  margin-top: 20px;
  font-size: calc(18px + (21 - 20) * ((100vw - 320px) / (1920 - 300)));
  line-height: 1.67;
  color: #293e40;
}

.cirItem.active {
  z-index: 1;
  opacity: 1;
  transform: scale(1);
  transition: 0.5s;
}

.title-box p {
  font-size: 18px;
  line-height: 1.67;
  color: #fff;
  margin: 0;
}

.dotCircle .itemDot:hover,
.dotCircle .itemDot.active {
  color: #ffffff;
  transition: 0.5s;
  background: #7d4ac7;
  background: -moz-linear-gradient(left, 0%, #a733bb 100%);
  background: -webkit-linear-gradient(left, #017aba 0%, #01a2a9 100%);
  background: linear-gradient(to right, #2f6ab3 0%, #01a2a9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7d4ac7', endColorstr='#a733bb', GradientType=1);
  border: 2px solid #ffffff;
  -webkit-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.13);
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.13);
}