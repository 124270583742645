.section-header h3 {
  font-size: 32px;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  padding: 0 15px;
  padding-bottom: 7px;
}

@media (max-width: 576px) {
  .section-header h3 {
    font-size: 26px;
  }
}

.section-header h3::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-header h3::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #2f6ab3;
  bottom: 0;
  left: calc(50% - 20px);
  line-height: normal;
}

.section-header p {
  text-align: center;
  padding-bottom: 30px;
  color: #333;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
#faq {
  padding: 60px 0 40px 0;
}

.faq .accordion-list {
  padding: 0 100px;
}

.faq .accordion-list ul {
  padding: 0;
  list-style: none;
}

.faq .accordion-list li+li {
  margin-top: 15px;
}

.faq .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .accordion-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .accordion-list .icon-help {
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  right: 0;
  left: 0;
  color: #2f6ab3;
}

.faq .accordion-list .icon-show,
.faq .accordion-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .accordion-list .icon-show {
  display: none;
}

.faq .accordion-list a.collapsed {
  color: #343a40;
}

.faq .accordion-list a.collapsed:hover {
  color: #5777ba;
}

.faq .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .accordion-list {
    padding: 0;
  }
}

.section-bg {
  background-color: #f2f5fa;
}

@media (max-width:768px) {
  #faq {
    padding: 40px 0;
  }
}