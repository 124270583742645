/* animation configuration */

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

/* animation configuration ends here */
.animatedBackground {
  background-repeat: repeat !important;
  background-size: auto !important;
  animation: animatedBackground 20s linear infinite;
  -ms-animation: animatedBackground 20s linear infinite;
  -moz-animation: animatedBackground 20s linear infinite;
  -webkit-animation: animatedBackground 20s linear infinite;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 0;
  }
}

@-webkit-keyframes animatedBackground {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 0;
  }
}

@-ms-keyframes animatedBackground {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 0;
  }
}

@-moz-keyframes animatedBackground {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 0;
  }
}

.rotateme {
  animation-name: rotateme;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: rotateme;
  -webkit-animation-duration: 40s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotateme;
  -moz-animation-duration: 40s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: rotateme;
  -ms-animation-duration: 40s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: rotateme;
  -o-animation-duration: 40s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotateme {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotateme {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(360deg);
  }
}

.animatedBackground {
  background-repeat: repeat !important;
  background-size: auto !important;
  animation: animatedBackground 20s linear infinite;
  -ms-animation: animatedBackground 20s linear infinite;
  -moz-animation: animatedBackground 20s linear infinite;
  -webkit-animation: animatedBackground 20s linear infinite;
}

div#Bannerid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
