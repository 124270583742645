#hportfolio {
  padding: 60px 0 0 0;
}


.main-section-portfolio {

  margin-bottom: 50px;
  border-radius: 10px;
}

.main-section-portfolio1 {
  width: 100%;
  margin-bottom: 50px;
  border-radius: 10px;
}

.inner-section-portfolio {
  border-radius: 10px;
  background: -moz-linear-gradient(left, 0%, #a733bb 100%);
  background: -webkit-linear-gradient(left, #017aba 0%, #01a2a9 100%);
  background: linear-gradient(to right, #2f6ab3 0%, #01a2a9 100%);
  display: flex;
  overflow: hidden;
}

.inner-section-portfolio .slick-slider .slick-track div {
  font-size: 0;
}

.paragraph-wrapper p {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  text-align: center;
}

.paragraph-wrapper {
  margin-bottom: 20px;
  text-align: center;
}

.question-title {
  margin-bottom: 20px;
  text-align: center;

}

.question-title span {
  color: white;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

.btn-more-projects button {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 35px;
  color: white;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 5px;
  display: inline-block;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.btn-more-projects button:hover {
  background-color: white;
  color: #333;
}

.left-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: calc(100% - 500px);
}

.right-wrap {
  max-width: 500px;
  width: 100%;
}

.slider-new-portfolio img {
  width: 100%;
  object-fit: cover;
  height: 430px;
}

.slider-new-portfolio button.slick-arrow.slick-prev {
  left: -15px;
  top: 25%;
  right: auto;
  width: 42px;
  height: 42px;
  line-height: 42px;
  margin-top: calc(-42px / 2);
  font-size: 18px;
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.1) 100%) !important;
  border-radius: 50%;
}

.slider-new-portfolio button.slick-arrow.slick-next {
  right: -15px;
  top: 25%;
  width: 42px;
  height: 42px;
  line-height: 42px;
  margin-top: calc(-42px / 2);
  font-size: 18px;
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.1) 100%) !important;
  border-radius: 50%;
}

.slider-new-portfolio .slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 22px;
  line-height: 2;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider-new-portfolio .slick-next:before,
.slick-next:before {
  font-family: "slick";
  font-size: 22px;
  line-height: 2;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}












@media screen and (max-width:992px) {
  .left-wrap {
    max-width: calc(100% - 360px);
  }

  .right-wrap {
    max-width: 360px;
  }

  .slider-new-portfolio img {
    height: 300px;
  }

  .question-title span {
    font-size: 26px;
    line-height: normal;
  }
}


@media screen and (max-width:768px) {
  div#port {
    padding: 0;
  }

  .inner-section-portfolio {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 150px;
    overflow: visible;
  }

  .left-wrap {
    max-width: 100%;

  }

  .right-wrap {
    margin-top: -150px;
    border-radius: 10px;
    overflow: hidden;
  }
}




@media screen and (max-width:480px) {
  .inner-section-portfolio {
    padding: 0 20px;
  }

  .left-wrap {
    padding: 20px 0;
  }

  .slider-new-portfolio img {
    height: 280px;
  }
}















/* @media (max-width:992px) {
  .slider-new-portfolio .slick-list {
    bottom: unset;
  }
} */
/* laptop */

/* @media (max-width:1399px) {
  .main-section-portfolio {
    padding-bottom: 330px;
  }



  .slider-new-portfolio img {
    border-radius: 10px;
    width: 100%;
  }


}


@media (max-width:1199px) {
  #port .slick-list {
    width: 340px;
  }

  .slider-new-portfolio button.slick-arrow.slick-next {
    right: -100px;
  }

  .main-section-portfolio {
    padding-bottom: 330px;
  }

  .container.main-section-portfolio {
    margin-top: 100px;
  }

  .slider-new-portfolio img {
    border-radius: 10px;
    width: 100%;
  }


}


@media screen and (min-width:1399px) {

  .slider-new-portfolio button.slick-arrow.slick-next {
    right: -10px;
  }

  .main-section-portfolio {
    padding-bottom: 330px;
  }

  .container.main-section-portfolio {
    margin-top: 100px;
  }
}

@media (max-width: 1200px) {
  .inner-section-portfolio {
    max-width: 992px;
    margin: 0 auto;
    padding: 40px;
  }

  .inner-section-portfolio .slick-slider {
    padding: 0 40px;
  }

  .inner-section-portfolio .slick-slider button.slick-prev {
    left: -10px;
  }

  .inner-section-portfolio .slick-slider button.slick-next {
    right: -10px;
  }
}

@media (max-width:991px) {
  .inner-section-portfolio {
    padding: 40px 50px 40px 50px;
    text-align: center;
  }

  .inner-section-portfolio>.row {
    flex-direction: column-reverse;
  }

  .inner-section-portfolio .slick-slider {
    margin: 0 auto;
    margin-top: -200px;
    margin-bottom: 35px;
    max-width: 465px;
  }

  .slider-new-portfolio button.slick-arrow.slick-prev,
  .slider-new-portfolio button.slick-arrow.slick-next {
    top: 70%;
  }

  .paragraph-wrapper p {
    padding: 0;
    text-align: center;
  }

  .question-title span {
    font-size: 20px;
    line-height: 23px;
  }

  .slider-new-portfolio img {
    border-radius: 10px;
    width: 100%;
    height: 310px;
  }

  .main-section-portfolio {
    padding-bottom: 330px;
  }

  .container.main-section-portfolio {
    margin-top: 160px;
  }
}

@media (max-width:767px) {

  .inner-section-portfolio {
    padding: 15px;
    text-align: center;
    width: 100%;
  }

  .paragraph-wrapper p {
    padding: 0;
  }

  .container.main-section-portfolio {
    margin-top: 242px;
    height: auto;
  }

  .question-title span {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
  }

  .slider-new-portfolio img {
    border-radius: 10px;
    width: 100%;
    height: 280px;
  }

  .slider-new-portfolio .slick-list {
    bottom: 440px;
    left: 50px;
  }

  #port .slick-list {
    width: 360px;
    position: unset;
  }
}


@media (max-width: 576px) {

  .container.main-section-portfolio {
    margin-top: 42%;
  }

  .question-title span {
    font-size: 26px;
  }

  .inner-section-portfolio {
    padding: 0;
    padding-bottom: 20px;
  }

  .inner-section-portfolio .slick-slider {
    margin-top: -45%;
    padding: 0;
  }

  #port .slick-list {
    width: 100%;
  }
} */