.Choose-part {
  padding: 0 0 30px 0;
}

.Choose-image {
  width: 12%;
  margin-bottom: 20px;
}

.Choose-content h4 {
  margin-bottom: 15px;

  font-size: 24px;
  font-weight: 700;
}

.Choose-content p {
  font-size: 16px;
  text-align: left;
  line-height: 25px;
}

.type-background {
  background-color: #263e5c;
}

.element-12 {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding: 80px 0;
}

.background-overlay {
  background-image: url(../../assets/images/section-01.png);
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.widget-wrap {
  padding: 10px;
}

.about-content {
  padding: 0 25px 0 0;
}

.image-box-wrapper {
  display: flex;
}

.image-box-img1 {
  width: 150px;
  margin-right: 17px;
}

.image-box-img img {
  transition-duration: 0.3s;
}

.image-box-content {
  width: 100%;
}

.image-box-content1 h3 {
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
  font-size: 35px;
}

.image-box-content1 p {
  color: #ffffff;
  font-size: 18px;
}



/* mobile */
@media screen and (max-width:767px) {
  .Benefits-detail {
    padding: 20px 15px 0px 15px;
    position: sticky;
    top: 150px;
  }

  .Benefits-title h3 {
    font-size: 30px;

  }
    .Choose-image {
      width: 18%;
      margin-bottom: 20px;
    }
        .image-box-img1 {
          width: 310px;
        }
                .image-box-content1 h3 {
           
                  font-size: 30px;
                }
}

/* tablet */
@media screen and (min-width:767px) and (max-width:1023px) {
  .Benefits-detail {
    padding: 70px 15px 0px 15px;
    position: sticky;
    top: 150px;
  }
}

/* laptop */
@media screen and (min-width:1024px) {}