.g-5 {
  --bs-gutter-x: 1.5rem !important;
}


/* Services Section
--------------------------------*/
#services {
  padding: 80px 0 40px 0;
}

#services .box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
  text-align: center;
  height: 100%;
}

@media (max-width: 576px) {
  #services .box {
    padding: 15px;
  }
}

#services .box:hover {
  transform: scale(1.1);
}

#services .icon {
  margin: 0 auto 15px auto;
  padding-top: 12px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

#services .icon i {
  font-size: 36px;
}

#services .title22 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

#services .title22 a {
  color: #111;
}

#services .box:hover .title22 a {
  color: #2f6ab3;
}

#services .description {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }

  #services {
    padding: 40px 0;
  }
}